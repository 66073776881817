



































































import Vue from "vue";
import { defineComponent, ref, computed, Ref } from "@vue/composition-api";
import useUser from "@/composables/user";
import i18n from "@/plugins/i18n";
export default defineComponent({
  props: {
    returnUrl: {
      type: String,
    },
  },

  setup(props, context) {
    const { login, isAuthenticated } = useUser();
    const formRef1 = computed((): Vue & {
      validate: () => boolean;
    } => {
      return context.refs.form1 as Vue & {
        validate: () => boolean;
      };
    });
    const router = context.root.$router;

    const userName = ref("");
    const password = ref("");
    const valid = ref(true);
    const unRules = [(v: any) => !!v || i18n.t("login.usernameRequired")];
    const pwRules = [(v: any) => !!v || i18n.t("login.passwordRequired")];
    const error: Ref<string | null> = ref(null);
    const processing = ref(false);

    const redirectToDashboard = () => {
      if (props.returnUrl) {
        window.location.href = props.returnUrl;
      } else {
        router.push({ name: "home" });
      }
    };

    const submitForm = async () => {
      try {
        error.value = null;
        if (formRef1.value.validate()) {
          processing.value = true;
          await login(userName.value, password.value);
          if(false == isAuthenticated.value) {
              throw new Error(i18n.t("login.failed") as string)
          }
          redirectToDashboard();
        }
      } catch (e) {
        error.value = e.message;
      } finally {
        processing.value = false;
      }
    };

    return {
      userName,
      password,
      valid,
      unRules,
      pwRules,
      processing,
      error,

      submitForm,
    };
  },
});
