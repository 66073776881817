<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col md="4">
        <v-card>
          <v-card-title>Upps</v-card-title>
          <v-card-text>{{ $i18n.t("404.message") }}</v-card-text>
          <v-card-actions class="justify-end d-flex">
            <v-btn plain @click="goHome">{{
              $i18n.t("404.btn")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  methods: {
    goHome() {
      window.location.href = '/login';
    }
  }
});
</script>
